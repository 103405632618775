<template lang="pug">
include ../../../configs/template
div.seafarerInfoList.pt-6.pb-4.row
  div.col-12
    +data-info('sailorFullName','statement.sailor_full_name_ukr')
  div.col-12
    +data-info('crewingManagerID','statement.manager.id')(v-if="checkAccess('crewingManagerStatements', 'showCrewing')")
  div.col-12
    +data-info('city','statement.manager.userprofile.city')
  div.col-sm-12.col-md-6
    +data-info('contractDateEnd','getDateFormat(statement.date_end_proxy)').col-sm-12.col-md-6
  div.col-sm-12.col-md-6
    +data-info('createDate','statement.created_at')
  div.col-sm-12.col-md-6
    +data-info('dateModified','statement.date_modified')
  div(v-if="statement.manager.userprofile.contact_info.length").col-12.text-left.p-0
    div(v-for="(record, index) of statement.manager.userprofile.contact_info"
      :key="index").col-12.p-0
      div(v-if="record.type_contact === contacts.phone_number.id").col-sm-12.col-md-6
        +data-info('phoneNumber','record.value')
      div(v-if="record.type_contact === contacts.telegram.id").col-sm-12.col-md-6
        +data-info('Telegram','record.value')
      div(v-if="record.type_contact === contacts.viber.id").col-sm-12.col-md-6
        +data-info('Viber','record.value')
      div(v-if="record.type_contact === contacts.email.id").col-sm-12.col-md-6
        +data-info('email','record.value')
  div.col-sm-12
    +data-info-status('status_document','getDirectoryObject({value: "statuses", id: statement.status_document})[nameLang]', 'getStatus(statement.status_document)')
</template>

<script>
import { getStatus, getDateFormat } from '@/mixins/main'
import { checkAccess } from '@/mixins/permissions'
import { mapState, mapGetters } from 'vuex'
import { contacts } from '@/configs/constants'
export default {
  name: 'crewingManagerStatementsInfo',
  data () {
    return {
      getStatus,
      checkAccess,
      getDateFormat,
      contacts
    }
  },
  computed: {
    ...mapGetters(['getDirectoryObject']),
    ...mapState({
      statement: state => state.statement.crewingManagerStatementById,
      nameLang: state => state.main.lang === 'en' ? 'name_eng' : 'name_ukr'
    })
  }
}

</script>
